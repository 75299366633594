import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { ContentContainer, ExternalContent } from '../components'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { mq } from '../utility'
import { fairtouchConfig } from './../config'

const InfomaterialPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params

  const [loaded, setLoaded] = React.useState(false)
  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  if (lang === '') {
    if (typeof window !== 'undefined') {
      navigate(`/en/${document.location.search}`)
    }

    return <></>
  }

  return (
    <PageFrame {...params} pageName="infomaterial" isTop>
      <>
        <FairtouchContentContainer fullwidth>
          <FairtouchContainer>
            <ExternalContent
              uri={`${
                fairtouchConfig.uri
              }/smart-infrastructure/index.html?logo=false&langswitch=false&lccc=${
                lang === 'de' ? 'de-DE' : 'en-EN'
              }`}
            />
          </FairtouchContainer>
        </FairtouchContentContainer>
      </>
    </PageFrame>
  )
}

export default InfomaterialPage

const FairtouchContentContainer = styled(ContentContainer)(
  ({ theme: { colors, grid } }) => `
  margin-top:70px;

  ${mq[3]}{
    margin-top:155px;
  }
  ${mq[4]}{
    margin-top:145px;
  }
`
)

const FairtouchContainer = styled.div(
  ({ theme: { colors, grid } }) => `
  height:calc(100vh - 70px);
  ${mq[3]}{
    height:calc(100vh - 105px);
  }
   ${mq[4]}{
    height:calc(100vh - 95px);
  }
  width:100%;
`
)
